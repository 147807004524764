import bcryptjs from "bcryptjs";
import CryptoJS from 'crypto-js'

import apiService from './api.service';
import authService from "./auth.service";
import badgesService from "./badges.service";

class UserService {

    API_ENDPOINT = 'user'

    createUser(username, firstname, lastname, accessCode, classreference) {
        const enabled = true
        const roles = [{'name': 'Learner'}]
        const password = bcryptjs.hashSync(accessCode, authService.CLIENT_SALT)
        accessCode = this.encryptCode(accessCode)
        const islogged = false
        return Promise.all([
            apiService.post(this.API_ENDPOINT, { username, firstname, lastname, password, enabled, roles, islogged, classreference }),
            apiService.post(`${this.API_ENDPOINT}/profile`, { username, skills: [1, 2, 3, 4, 5] }),
            apiService.post(`${this.API_ENDPOINT}/student`, { username, name: `${firstname} ${lastname}`, active: true, accesscode: accessCode })
        ]).then(() => badgesService.createBadges(username, [1, 2, 3, 4, 5], [1, 2, 3, 4]))
    }

    disableUser(username) {
        return apiService.put(`${this.API_ENDPOINT}/${username}/disable`).then(res => res.data)
    }

    getUserByUsername(username) {
        return Promise.all([
            apiService.get(`${this.API_ENDPOINT}/${username}`),
            apiService.get(`${this.API_ENDPOINT}/student/${username}`)
        ]).then(([user, details]) => {
            user.data.user.accessCode = this.decryptCode(details.data.studentdetail.accesscode)
            return user.data.user
        })
    }

    encryptCode(code) {
        return CryptoJS.AES.encrypt(code, authService.TEACHER_KEY).toString()
    }

    decryptCode(cipher) {
        return CryptoJS.AES.decrypt(cipher, authService.TEACHER_KEY).toString(CryptoJS.enc.Utf8)
    }

    getUserProfile(username) {
        return apiService.get(`${this.API_ENDPOINT}/profile/${username}`).then(data => data.data.userprofile)
    }

    getUserImagesCount(username, skillid) {
        return apiService.get(`${this.API_ENDPOINT}/task/${username}/img/skill/${skillid}/count`).then(data => data.data.count)
    }

    getuserSequence(username) {
        return apiService.get(`${this.API_ENDPOINT}/activitymodel/${username}`)
			.then(res => {
				let sequence = res.data.activitymodel.phasecontainer
				let r = {}
				sequence.forEach(phase => r[phase.phase] = phase.steps)
				return r
			})
    }

	getPortfolio(username, skills) {
		return Promise.all(skills.map(skill => apiService.get(`${this.API_ENDPOINT}/task/${username}/skill/${skill}/images`)))
			.then(images => {
                let allData = []
                images.forEach(entry => allData.push(...entry.data.data))
                return Promise.all(allData.map(image => this.getImageTexts(image.username, image._id))).then(data => {
					if (!!data) data.forEach((entry, index) => allData[index].answers = entry)
                    return allData
				})
			})
	}

	getImageTexts(username, answerid, count = 3) {
		return apiService.get(`${this.API_ENDPOINT}/task/${username}/task/${answerid}/next/${count}`)
			.then(res => res.data.data)
	}

    getAnswersBySkillLevel = (username, skillid, phase) => {
        return apiService.get(`${this.API_ENDPOINT}/task/${username}/skill/${skillid}/phase/${phase}`)
            .then(res => res.data.usertasks)
    }

}

export default new UserService();



