//Router for supporting multiple routes
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Component } from 'react';

//Custom imports as part of Assessmake21
import Login from './components/screens/login';
import ContentMain from './components/screens/contentmain';
import Auth from './components/auth';
import './App.scss';

function PrivateRoute ({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={props =>
      Auth.getAuth() ? (
      <Component {...props} />
      ) : (
      <Redirect
      to={{
      pathname: "/login"
      }} />
      )
    }
  />
  )
}

class App extends Component {

  constructor() {
    super()
    this.state = {
      user: null
    }
  }

  setAppState = (newState, cb = null) => {
    this.setState({newState}, !!cb ? cb : null)
  }

  render() {
    const { history } = this.props;
    return (
      <Router basename="/" history={history}>
      { <Route
                exact
                path="/"
                render={() => {
                    return (
                     
                      <Redirect to="/home" />
                    )
                }}
              /> }
          <Route exact path='/login' component={Login}/>
          <PrivateRoute path='/home' component={ContentMain}/>
          </Router>
    );
  }
}

export default App;
