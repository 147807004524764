import apiService from './api.service';

class SkillService {

    API_ENDPOINT = 'skill'

    getSkill(skillid) {
        return apiService.get(`${this.API_ENDPOINT}/${skillid}`).then(data => data.data.skill)
    }

}

export default new SkillService();



