import React, { Component } from 'react';

import '../styles/addstudents.scss';
import AddStudentsBanner from '../assets/img/new-student.png';
import classroomService from '../services/classroom.service';
import { withTranslation } from 'react-i18next';
class AddStudents extends Component {

	constructor(props) {
		super(props)
		this.state = {
			registeredStudents: [],
			tempName: ''
		}
	}

	registerStudent = () => {
		let { tempName } = this.state
		tempName = tempName.trim()
		if (tempName !== '') {
			let tempStudents = this.state.registeredStudents
            const splitName = tempName.split(' ')
			const student = {
				firstname: splitName[0],
				lastname: splitName.length > 1 ? splitName.slice(1).join(' ') : '',
				accessCode: classroomService.generateAccessCode(),
				username: classroomService.generateUsername(tempName)
			}
			tempStudents.push(student)
			this.setState({ registeredStudents: tempStudents, tempName: '' })
		}
	}

	handleInputChange = (event) => this.setState({ tempName: event.target.value })

	handleInputReturn = (event) => {
		if (event.key === 'Enter') {
			this.registerStudent()
		}
	}

	handleStudentDelete = student => {
		let { registeredStudents } = this.state
		registeredStudents = registeredStudents.filter(stu => stu.username !== student.username)
		this.setState({ registeredStudents })
	}

	handleSubmit = () => {
		if (this.state.registeredStudents.length > 0) {
			this.props.addStudents(this.state.registeredStudents)
			this.handleCancel()
		}
	}

	handleCancel = () => {
		this.setState({ registerStudent: [], tempName: ''})
		this.props.navigateTo()
	}

	render() {
		const { t } = this.props;
		return <div id="addstudents-component">
			<div className='back' onClick={this.props.navigateTo}>&lt; {t('back')}</div>
			<div className="intro container-fluid row">
				<div className="body col">
					<div className="header">{t('addanewstudent')}</div>
					<div className="text">{t('doyouhaveanystudents')}</div>
				</div>
				<div className="image col-5">
					<img src={AddStudentsBanner} alt="Student overview"/>
				</div>
			</div>
			<div className="content">
				<div className="input d-flex flex-row align-self-center">
					<input type="text" placeholder={t('addanewstudent')} className='flex-grow-1' onChange={this.handleInputChange} onKeyDown={this.handleInputReturn} value={this.state.tempName} />
					<div className='add-button'>
						<div className="secondary-button" onClick={this.registerStudent}>{t('add')} +</div>
					</div>
				</div>
				<div className="registered-students">
					{
						this.state.registeredStudents.map(student => (
							<div className="student d-flex flex-row" key={student.username}>
								<div className="details flex-grow-1">
									<div className="name">{`${student.firstname} ${student.lastname}`}</div>
									<div className="code">{student.accessCode.toUpperCase().split('').join('-')}</div>
								</div>
								<div className="actions d-flex flex-row">
									<div className="rename">{t('rename')}</div>
									<div className="delete" onClick={() => this.handleStudentDelete(student)}>{t('delete')}</div>
								</div>
							</div>
						))
					}
				</div>
				<div className="actions d-flex justify-content-end">
					<div className="secondary-button" onClick={this.handleCancel}>{t('cancel')}</div>
					<div className={ this.state.registeredStudents.length > 0 ? 'primary-button' : 'disabled-button'} onClick={this.handleSubmit}>{t('add')}</div>
				</div>
			</div>
		</div>
	}
}

export default withTranslation()(AddStudents);