import React, { Component } from 'react';

import StudentSelection from './studentselection';
import ApproachSelection from './approachselection';
import DurationSelection from './durationselection';
import LanguageSelection from './languageselection';
import WizardSummary from './wizardsummary';
import CodesDisplay from './codesdisplay';

import classroomService from '../../services/classroom.service';

import '../../styles/classroomwizard.scss'
import ParallelApproach from '../../assets/img/parallel-approach.png';
import ParallelAtTheEnd from '../../assets/img/parallel-at-the-end.png';
import AtTheEnd from '../../assets/img/at-the-end.png';
import ShortDuration from '../../assets/img/short-duration.png';
import MediumDuration from '../../assets/img/medium-duration.png';
import LongDuration from '../../assets/img/long-duration.png';

class ClassroomWizard extends Component {

	WIZARD_FLOW = [
		'studentSelection',
		'approachSelection',
		'durationSelection',
		'languageSelection',
		'summary',
		'codesDisplay'
	]
	APPROACH_OPTIONS = [
		{
			order: 1,
			value: 1,
			title: 'parallelapproach',
			image: ParallelApproach,
			description: 'studentsinputwhatskills'
		},
		{
			order: 2,
			value: 2,
			title: 'challengesatend',
			image: ParallelAtTheEnd,
			description: 'atendmessagedetail'
		},
		{
			order: 3,
			value: 3,
			title: 'atendapproach',
			image: AtTheEnd,
			description: 'atenddetail'
		},
	]

	DURATION_OPTIONS = [
		{
			order: 1,
			value: 5,
			title: 'shortduration',
			subtitle: "max28sessions",
			image: ShortDuration,
			description: 'shortdurationdescription'
		},
		{
			order: 2,
			value: 8,
			title: 'mediumduration',
			subtitle: "max38sessions",
			image: MediumDuration,
			description: 'mediumdurationdescription'
		},
		{
			order: 3,
			value: 12,
			title: 'longduration',
			subtitle: "max48sessions",
			image: LongDuration,
			description: 'longdurationdescription'
		},
	]

	LANGUAGE_OPTIONS = [
		{
			text: 'english',
			value: 'en'
		},
		{
			text: 'swedish',
			value: 'sv'
		},
		{
			text: 'greek',
			value: 'el'
		},
	]

	constructor(props) {
		super(props)
		if (!!props.classroom) {
			this.state = props.classroom
			this.state.currentStep = 'summary'
			this.state.codes = []
		}
		else this.state = {
			student_members: [],
			approachtype: null,
			duration: null,
			language: 'en',
			activitymodelref: 1,
			name: '',
			toolsref: [],
			codes: [],
			currentStep: this.WIZARD_FLOW[0]
		}
		this.state.savingClassroom = false
		this.state.reachedSummary = false
	}

	handleClassroomSubmit = () => {
		if (!this.state.savingClassroom) {
			this.setState({savingClassroom: true})
			let { student_members, approachtype, duration, language, name, toolsref, activitymodelref } = this.state
			switch(duration) {
				case 5: default: activitymodelref = 1; break;
				case 8: activitymodelref = 2; break;
				case 12: activitymodelref = 3; break;
			}
			if (!this.state._id) {
				classroomService.createClassroom(
					localStorage.getItem('username'),
					student_members,
					name,
					duration,
					approachtype,
					toolsref,
					language,
					activitymodelref
				)
				.then(classroom => {
					if (!!classroom.classroom && !!classroom.classroom.id) {
						this.props.onNewClassroom(classroom.classroom)
						this.setState({ codes: classroom.codes, savingClassroom: false })
						if (classroom.codes.length > 0) this.handleNavigateManual('codesDisplay')
						else this.props.navigateTo()
					}
				})
			} else {
				classroomService.updateClassroom(this.state).then(data => {
					let classroom = this.state
					delete classroom.currentStep
					delete classroom.reachedSummary
					delete classroom.savingClassroom
					delete classroom.codes
					this.props.updateClassroom(classroom)
					this.props.navigateTo()
				})
			}
		}
	}

	handleStudentDelete = (username) => {
		let { student_members, _id } = this.state
		student_members = student_members.filter(student => student !== username)
		classroomService.removeStudentFromClassroom(_id, username).then(([classroom, user]) => {
			let { codes } = this.state
			codes = codes.filter(code => code.username !== username)
			this.setState({ student_members, codes })
		})
	}

	handleDataUpdate = (key, value) => {
		this.setState({[key]: value})
	}

	handleNavigateNext = () => {
		let flowindex = 0
		if (!!this.state.reachedSummary) {
			flowindex = this.WIZARD_FLOW.indexOf('summary')
		} else {
			flowindex = this.WIZARD_FLOW.indexOf(this.state.currentStep)
			if (flowindex < 0) flowindex = 0
			else if (flowindex < this.WIZARD_FLOW.length - 1) ++flowindex
		}
		this.setState({currentStep: this.WIZARD_FLOW[flowindex]})
	}

	handleNavigatePrevious = () => {
		let flowindex = 0
		if (!!this.state.reachedSummary) {
			flowindex = this.WIZARD_FLOW.indexOf('summary')
		} else {
			flowindex = this.WIZARD_FLOW.indexOf(this.state.currentStep)
			if (flowindex < 0) flowindex = 0
			if (flowindex > 0) --flowindex
		}
		this.setState({currentStep: this.WIZARD_FLOW[flowindex]})
	}

	handleNavigateManual = (flowStep) => {
		let flowindex = this.WIZARD_FLOW.indexOf(flowStep)
		if (flowindex < 0) flowindex = 0
		this.setState({currentStep: this.WIZARD_FLOW[flowindex]})
	}

	getContentByState = () => {
		switch (this.state.currentStep) {
			case 'approachSelection':
				return <ApproachSelection
					language={this.state.language}
					approach={this.state.approachtype}
					options={this.APPROACH_OPTIONS}
					updateData={(approach) => this.handleDataUpdate('approachtype', approach)}
					navigateTo={this.props.navigateTo}
					goNext={this.handleNavigateNext}
					goPrevious={this.handleNavigatePrevious}
					go={this.handleNavigateManual} />
			case 'durationSelection':
				return <DurationSelection
					language={this.state.language}
					duration={this.state.duration}
					options={this.DURATION_OPTIONS}
					updateData={(duration) => this.handleDataUpdate('duration', duration)}
					navigateTo={this.props.navigateTo}
					goNext={this.handleNavigateNext}
					goPrevious={this.handleNavigatePrevious}
					go={this.handleNavigateManual} />
			case 'languageSelection':
				return <LanguageSelection
					language={this.state.language}
					options={this.LANGUAGE_OPTIONS}
					updateData={(language) => this.handleDataUpdate('language', language)}
					navigateTo={this.props.navigateTo}
					goNext={this.handleNavigateNext}
					goPrevious={this.handleNavigatePrevious}
					go={this.handleNavigateManual} />
			case 'summary':
				return <WizardSummary
					data={this.state}
					language={this.state.language}
					approachOptions={this.APPROACH_OPTIONS}
					durationOptions={this.DURATION_OPTIONS}
					updateData={(name) => this.handleDataUpdate('name', name)}
					reachedSummary={() => this.handleDataUpdate('reachedSummary', true)}
					saveClassroom={this.handleClassroomSubmit}
					navigateTo={this.props.navigateTo}
					goNext={this.handleNavigateNext}
					goPrevious={this.handleNavigatePrevious}
					go={this.handleNavigateManual} />
			case 'codesDisplay':
				return <CodesDisplay
					language={this.state.language}
					codes={this.state.codes}
					classroom={this.state}
					handleDeleteStudent={this.handleStudentDelete}
					navigateTo={this.props.navigateTo}
					goPrevious={this.handleNavigatePrevious}
					go={this.handleNavigateManual} />
			case 'studentSelection':
			default:
				return <StudentSelection
					language={this.state.language}
					students={this.state.student_memebers}
					updateData={(students) => this.handleDataUpdate('student_members', students)}
					navigateTo={this.props.navigateTo}
					goNext={this.handleNavigateNext}
					goPrevious={this.handleNavigatePrevious}
					go={this.handleNavigateManual} />
		}
	}

	render() {
		return <div id="classroomwizard-component">
			<this.getContentByState />
		</div>
	}
}

export default ClassroomWizard;