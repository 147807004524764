import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import classroomService from '../../services/classroom.service';
import skillService from '../../services/skill.service';

import Home from './home';
import Auth from '../auth';
import SideNavigation from '../sidenavigation';
import ClassroomWizard from './classroomwizard';
import ClassroomProfile from './classroomprofile';

import '../../styles/contentmain.scss'
import LogoutAssistant from '../../assets/logout/Human_Explain2.png'

class ContentMain extends Component {

  constructor(props) {
    super(props);
    this.state = {
      classrooms: [],
      user: {},
      showLogoutModal: false,
      appLocation: 'home',
      classroomToDisplay: null,
      childStep: null,
      skills: []
    }
  }

  componentDidMount() {
    classroomService.getClassrooms(localStorage.getItem('username'))
      .then(classrooms => this.setState({classrooms}))
    let tempUser = {}
    
    tempUser.username = localStorage.getItem('username') || ''
    tempUser.firstname = localStorage.getItem('firstname') || ''
    tempUser.lastname = localStorage.getItem('lastname') || ''
    tempUser.email = localStorage.getItem('email') || ''
    this.setState({user: tempUser})
    const skillIDs = [1, 2, 3, 4, 5]
    skillIDs.forEach(skillid => skillService.getSkill(skillid).then(skill => {
      const { skills } = this.state
      skills.push(skill)
      this.setState({ skills: skills.sort((a, b) => a._id - b._id)})
    }))
  }

  handleLogoutRequest = () => {
    this.setState({showLogoutModal: true})
  }

  handleLogout = () => {
    localStorage.clear()
    Auth.signout();
    this.props.history.push("/login");
  }

  addClassroom = (classroom) => {
    let { classrooms } = this.state
    classrooms.push(classroom)
    this.setState({classrooms})
  }

  handleClassroomUpdate = (updatedClassroom) => {
    let { classrooms } = this.state
    let classroomIndex = classrooms.findIndex(classroom => classroom._id === updatedClassroom._id)
    if (classroomIndex > -1) {
      classrooms[classroomIndex] = updatedClassroom
      if (this.state.classroomToDisplay._id === updatedClassroom._id) {
        this.setState({ classroomToDisplay: null },
          () => this.setState({ classroomToDisplay: updatedClassroom, childStep: 'studentGrid'}))
      }
      this.setState({ classrooms })
    }
  }

  navigateTo = (newLocation = 'home', extras) => {
    if (!!extras) this.setState(extras)
    if (newLocation !== 'classroom-profile') this.setState({ classroomToDisplay: null })
    this.setState({ appLocation: newLocation });
  }

  getContentByState = () => {
    switch ( this.state.appLocation) {
      case 'classroom-wizard':
        return <ClassroomWizard
          onNewClassroom={this.addClassroom}
          navigateTo={this.navigateTo}
          />;
      case 'classroom-profile':
        return <ClassroomProfile
          classroom={this.state.classroomToDisplay}
          navigateTo={this.navigateTo}
          updateClassroom={this.handleClassroomUpdate}
          step={this.state.childStep}
          skills={this.state.skills}
          />
      case 'home':
      default:
        return <Home
          classrooms={this.state.classrooms}
          user={this.state.user}
          navigateTo={this.navigateTo}
          />;
    }
  }

  render() {
    const { t } = this.props;
    return (<div id='contentmain-component' className='d-flex flex-row'>
      <div className='flex-column' id='sidebar'>
        <SideNavigation
          navigateTo={this.navigateTo}
          classrooms={this.state.classrooms}
          onLogout={this.handleLogoutRequest}
          location={this.state.appLocation}
          activeClassroom={this.state.classroomToDisplay}
          />
      </div>
      <div className="flex-column" id='content'>
      <this.getContentByState/>
      </div>
      <Modal
        show={this.state.showLogoutModal}
        backdrop="static"
        keyboard={false}
        id='modal' >
        <Modal.Header>{t('comebacksoon')}</Modal.Header>
        <Modal.Body>
          <img src={LogoutAssistant} alt="ASSESSMAKE21 assistant"/><br/>
          {t('areyousuretologout')}
        </Modal.Body>
        <Modal.Footer>
          <Button className="primary-button" onClick={this.handleLogout}>{t('logout')}</Button>
          <Button className="secondary-button" onClick={() => this.setState({showLogoutModal: false})}>{t('cancel')}</Button>
        </Modal.Footer>
      </Modal>
    </div>);
  }
}

export default withTranslation()(ContentMain);
