import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import userService from '../../services/user.service';
import taskService from '../../services/task.service.';

import SkillMenu from '../skillmenu';

import '../../styles/studentportfolio.scss';
import StudentPortfolioImage from '../../assets/img/portfolio.png';


class StudentPortfolio extends Component {

	constructor(props) {
		super(props)
		this.state = {
			portfolio: [],
			displayPortfolio: [],
			tasks: {},
			displayIndex: 0,
			selectedSkill: props.skills.length > 0 ? props.skills[0]._id : 0,
			selectedLevel: 1,
			isFirstLoad: true
		}
	}

	componentDidMount() {
		const { skillid, level } = this.props
		this.setState({
			selectedSkill: !!skillid ? skillid : this.state.selectedSkill,
			selectedLevel: !!level ? level : this.state.selectedLevel
		})
		userService.getPortfolio(this.props.student.username, this.props.skills.map(skill => skill._id)).then(portfolio => {
			const taskIDs = portfolio.map(example => example.answers.map(answer => answer.taskid)).filter((example, index, array) => array.indexOf(example) === index).join().split(',')
			taskIDs.forEach(taskid => !!taskid && taskService.getTask(taskid).then(task => {
				const { tasks } = this.state
				tasks[task._id] = task
				this.setState({ tasks })
			}))
			this.setState({ portfolio }, this.changeSkill)
		})
	}

	getChallengeByAnswer = answer => {
		const task = this.state.tasks[answer.taskid]
		const skillIndex = this.props.skills.map(skill => skill._id).indexOf(answer.skillid)
		if (!!task && skillIndex > -1) {

			// if( this.props.i18n.language != 'en') {

			// 	// just a placeholder
			// 	var lang = "english";

			// 	if( this.props.i18n.language === 'el' ) {
			// 		lang = "greek";
			// 	}
			// 	else if( this.props.langauge === 'sv' ) {
			// 		lang = "swedish";
			// 	}

			// 	const translation = task.translation.find( ({language}) => language === lang );
			// 	task.title = translation.title;
			// }

			task.title = task.title.replace('[Skill_Tagged]', this.props.t(this.props.skills[skillIndex].title.toLowerCase()))
			return task
		} else return ''
	}

	getSkillText = (skillid) => {
		switch(skillid) {
			case 1: return 'collaboration'
			case 2: return 'creativity'
			case 3: return 'problem-solving'
			case 4: return 'life/social'
			case 5: return 'communication'
			default: return 'Unknown skill'
		}
	}

	changeSkill = (skillid = this.state.selectedSkill) => {
		this.setState({
			displayPortfolio: this.state.portfolio.filter(example =>
				example.skillid === (!!this.state.isFirstLoad ? this.props.skillid : skillid) &&
				example.phase === (!!this.state.isFirstLoad ? this.props.level : 1)),
			selectedLevel: !!this.state.isFirstLoad ? this.props.level : 1,
			selectedSkill: !!this.state.isFirstLoad ? this.props.skillid : skillid,
			displayIndex: 0,
			isFirstLoad: false
		})
	}

	changeLevel = level => {
		if (!isNaN(level)) level = +level
		else level = 1
		this.setState({
			displayPortfolio: this.state.portfolio.filter(example => example.skillid === this.state.selectedSkill && example.phase === level),
			selectedLevel: level,
			displayIndex: 0,
			isFirstLoad: false
		})
	}

	changeStep = (stepIndex) => this.setState({ displayIndex: (stepIndex > this.state.displayPortfolio.length - 1 || stepIndex < 0) ? 0 : stepIndex})

	previousStep = () => this.changeStep(this.state.displayIndex - 1)
	nextStep = () => this.changeStep(this.state.displayIndex + 1)

	render() {
		const { t } = this.props;
		const example = this.state.displayPortfolio[this.state.displayIndex]
		return <div id="studentportfolio-component">
			<div className='back' onClick={this.props.goBack}>&lt; Back</div>
			<div className="intro container-fluid row">
							<div className="body col">
								<div className="header">{t('portfolio')}</div>
								<div className="text">Check out the examples of your students to see how they are progressing with their 21st century skills through their project</div>
							</div>
							<div className="image col-5">
								<img src={StudentPortfolioImage} alt="Student portfolio"/>
							</div>
						</div>
			<div className="main-content d-flex flex-row">
				<SkillMenu
					skills={this.props.skills}
					activeSkill={this.state.selectedSkill}
					onSkillSelected={this.changeSkill} />
				<div className="content flex-grow-1">
					<div className="level-selector">
						<select name="level" value={this.state.selectedLevel} onChange={e => this.changeLevel(e.target.value)}>
							<option value="-1">{t('chooselevel')}</option>
							<option value="1">{t('level')} 1</option>
							<option value="2">{t('level')} 2</option>
							<option value="3">{t('level')} 3</option>
							<option value="4">{t('level')} 4</option>
							<option value="5">{t('level5')}</option>
						</select>
					</div>
					{ !!example &&
						<div className="example">
							<div className="image">
								<div className="title">{`${t(this.getSkillText(example.skillid))}: ${t('level')} ${example.phase} - ${t('example')} ${this.state.displayIndex + 1}`}</div>
								<div className="img"><img src={`data:png;base64,${Buffer.from(example.img.data).toString('base64')}`} alt="Example uploaded" /></div>
								<div className="date">{(new Date(example.createdAt)).toLocaleDateString()}</div>
							</div>
							{ example.answers.map((answer, index) => {
								return <div className={`answers ${index > 0 && 'with-border'} d-flex flex-row`} key={`${answer._id}`}>
									<div className="number">{index + 1}</div>
									<div className="content">
										<div className="challenge">{this.getChallengeByAnswer(answer).title}</div>
										<div className="answer">{
											!isNaN(answer.answers[0]) ? <div className='rating-answer d-flex flex-row'>
												{ [0, 1, 2, 3, 4].map(mark => <div className={`mark ${ mark <= answer.answers[0] && 'active'}`}></div>) }
											</div> : answer.answers[0]
										}
										</div>
									</div>
								</div>
							})}
						</div>
					}
					{ this.state.displayPortfolio.length > 0 &&
						<div className="pagination">
							<div className="step previous" onClick={this.previousStep}><IoIosArrowBack /></div>
							{
								this.state.displayPortfolio.map((step, index) =>
									<div className={`step ${this.state.displayIndex === index ? 'active' : ''}`} key={index} onClick={() => this.changeStep(index)}>{index + 1}</div>
								)
							}
							<div className="step next" onClick={this.nextStep}><IoIosArrowForward /></div>
						</div>
					}
				</div>
			</div>
			</div>
	}
}

export default withTranslation()(StudentPortfolio);