import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import '../styles/skillranking.scss'
import CollaborationColorIcon from '../assets/img/Collaboration.png'
import CreativityColorIcon from '../assets/img/Creativity.png'
import ProblemSolvingColorIcon from '../assets/img/ProblemSolving.png'
import LifeSocialColorIcon from '../assets/img/Life-Social-Skills.png'
import CommunicationColorIcon from '../assets/img/Communication.png'

class SkillRanking extends Component {

    SKILL_ICONS = {
        1: CollaborationColorIcon,
        2: CreativityColorIcon,
        3: ProblemSolvingColorIcon,
        4: LifeSocialColorIcon,
        5: CommunicationColorIcon
    }

	render() {
		return <div id="skillranking-component" className='box-shadow'>
			<div className="title">Skills Development</div>
			<div className="subtitle">Most tagged skills</div>
			{ this.props.skills.slice().sort((a, b) => b.tags.length - a.tags.length).map((skill, index) => skill.tags && <div className='skill d-flex flex-row' key={skill._id}>
				<div className='number'>{(index + 1) > 9 ? index + 1 : '0' + (index + 1) }</div>
				<div className='icon-image'><img src={this.SKILL_ICONS[skill._id]} alt={skill.title} /></div>
				<div className='content'>
					<div className='skill-name'>{skill.title}</div>
					<div className='count'>{skill.tags.length} total tags</div>
				</div>
			</div>)}
		</div>
	}
}

export default withTranslation()(SkillRanking)