import React, { Component } from 'react';

import '../../styles/approachselection.scss';
import { BsToggleOff } from 'react-icons/bs';
import { BsToggleOn } from 'react-icons/bs';
import SelectApproach from '../../assets/img/select-approach.png'
import { withTranslation } from 'react-i18next';

class ApproachSelection extends Component {

	constructor(props) {
        super(props);
    
    }

	handleValueChange = (e) => {
		let value = null
		try {
			value = +e.target.value
		}
		catch {
			value = null
		}
		this.props.updateData(value)
	}

	render() {
		
		const { t } = this.props;
		return <div id="approachselection-component">
			<div className="header">{t('chooseapproach')}</div>
			<div className='back' onClick={this.props.goPrevious}>&lt; {t('back')}</div>
			<div className="intro container-fluid row">
				<div className="body col">
					<div className="header">{t('approaches')}</div>
					<div className="text">{t('youknowstudents')}</div>
				</div>
				<div className="image col-5">
					<img src={SelectApproach} alt="Choose approach"/>
				</div>
			</div>
			<div className="container-fluid row approaches">
				{
					this.props.options && this.props.options.map(option => 
						<div className="col" key={option.value}>
							<input type="radio" name="approach" value={option.value} id={option.value} onChange={this.handleValueChange}/>
							<label className={`approach box-shadow ${this.props.approach === option.value ? 'active':''}`} htmlFor={option.value}>
								<div className="d-flex flex-row justify-content-between title">
									<div className="text">{t(option.title)}</div>
									{ this.props.approach === option.value ? <BsToggleOn/> : <BsToggleOff/> }
								</div>
								<div className="image"><img src={option.image} alt={t(option.title)}/></div>
								<div className="description">{t(option.description)}</div>
							</label>
						</div>
						)
				}
			</div>
			<div className="actions d-flex flex-row justify-content-end">
				<button className='secondary-button' onClick={this.props.goPrevious}>{t('cancel')}</button>
				<button className={ this.props.approach !== null ? 'primary-button' : 'disabled-button' } onClick={this.props.goNext}>{t('next')}</button>
			</div>
		</div>
	}
}

export default withTranslation()(ApproachSelection);