import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Carousel from "react-spring-3d-carousel";

import '../styles/examplescarousel.scss'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

class ExamplesCarousel extends Component {

	constructor() {
		super()
		this.state = {
			slides: [],
			slideIndex: 1
		}
	}

	componentDidMount() { this.reloadImages() }

	componentDidUpdate(prevProps) {
		if (prevProps.classroom._id !== this.props.classroom._id) this.reloadImages()
	}

	reloadImages = () => {
		const slides = []
		const { images } = this.props
		images.forEach((image, slideIndex) => {
			const slide = {
				key: image._id,
				content: <img src={`data:png;base64,${Buffer.from(image.img.data).toString('base64')}`} alt='User example' />,
				onClick: () => this.setState({ slideIndex }),
				student: this.getStudentName(image.username),
				skill: this.getSkillName(image.skillid)
			}
			slides.push(slide)
		})
		this.setState({ slides })
	}

	setSlide = index => {
		if (index < 0) index = this.state.slides.length - 1
		else if (index >= this.state.slides.length) index = 0
		this.setState({ slideIndex: index })
	}
	
	previousSlide = () => { this.setSlide(this.state.slideIndex - 1) }
	nextSlide = () => { this.setSlide(this.state.slideIndex + 1) }

	getStudentName = username => {
		const student = this.props.students.find(student => student.username === username)
		return !!student ? `${student.firstname} ${student.lastname}` : ''
	}

	getSkillName = skillid => {
		const skill = this.props.skills.find(skill => skill._id === skillid)
		return !!skill ? skill.title : ''
	}

	render() {
		const { slides, slideIndex } = this.state
		const slide = slides[slideIndex]
		if (slides.length > 0) return <div id="examplescarousel-component" className='box-shadow'>
			<div className="title">Latest examples <span className="count">{ slides.length > 9 ? slides.length : ('0' + slides.length) }</span></div>
			<div className="carousel" style={{ width: "80%", height: "200px", margin: "0 auto" }}>
				{ slides.length > 0 && <Carousel
					slides={slides}
					goToSlide={slideIndex}
					offsetRadius={1}
				/>}
				<div className="actions d-flex flex-row justify-content-around align-items-center">
					<div className="nav" onClick={this.previousSlide}><IoIosArrowBack /></div>
					{ !!slide && <div className="text">{`${slide.student || '' } / ${slide.skill}`}</div>}
					<div className="nav" onClick={this.nextSlide}><IoIosArrowForward /></div>
				</div>
			</div>
		</div>
		else return null
	}
}

export default withTranslation()(ExamplesCarousel)