import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

import authService from '../../services/auth.service';

import '../../styles/login.scss';
import Logo from '../../assets/img/assessmake21_logo.png';
import Assistant from '../../assets/login/Human_Expain.png';
import Auth from '../auth';
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../languageselector';
class Login extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errorMessage: undefined
        }
        this.changeLanguage=this.changeLanguage.bind(this);
    }

    changeLanguage = (lng) => {
        this.props.i18n.changeLanguage(lng);
    };

    handleChangeLanguage = language => {
        this.changeLanguage(language);
    }

    componentDidMount() {
        document.getElementById('email').addEventListener('keyup', ({key}) => {
            if (key === 'Enter') document.getElementById('password').focus()
        })
        document.getElementById('password').addEventListener('keyup', ({key}) => {
            if (key === 'Enter') this.handleLogin()
        })
    }

    handleChange = e => this.setState({[e.target.name]: e.target.value})
    
    handleLogin = () => {
        authService.login(this.state.email, this.state.password)
            .then(res => {
                localStorage.setItem('accessToken', res.accessToken)
                localStorage.setItem('username', res.username)
                localStorage.setItem('email', res.email)
                localStorage.setItem('firstname', res.firstname)
                localStorage.setItem('lastname', res.lastname)
                Auth.authenticate();
                this.props.history.push("/home");
            })
            .catch(err => {
                console.error(err)
                this.setState({errorMessage: 'incorrectcredentials', password: ''}, () => document.getElementById('password').focus())
            })
    }

    render() {
        const { t } = this.props;
        return (
        <div id="login-component" className='d-flex justify-content-around'>
            
            <div className="flex-column assistant">
                <img src={Assistant} alt="ASSESSMAKE21 assistant"/>
                
            </div>
            <div className="flex-column flex-row align-items-center">
            <LanguageSelector onChange={this.handleChangeLanguage} />
                <div className="logo">
                    <img src={Logo} alt="ASSESSMAKE21 logo" />
                    <div>{t('educatorsdashboard')}</div>
                </div>
                <form className='box-shadow d-flex flex-column align-items-center'>
                    <label htmlFor="email">{t('email')}</label>
                    <input type="text" name="email" id='email' value={this.state.email} onChange={this.handleChange}/>
                    <label htmlFor="password">{t('password')}</label>
                    <input type="password" name="password" id='password' value={this.state.password} onChange={this.handleChange}/>
                    { !!this.state.errorMessage && <div className="errorMessage align-self-start">{t(this.state.errorMessage)}</div> }
                    <button type='button' className='primary-button login' onClick={this.handleLogin}>{t('login')}</button>
                </form>
                <div className="signup">{t('donthaveanaccount')}<a href='localhost'>{t('signup')}</a></div>
            </div>
        </div>
        )
    }
}

export default withRouter(withTranslation()(Login));

