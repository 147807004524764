import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import userService from '../../services/user.service';

import ProgressMarker from '../progressmarker';
import StudentPortfolio from './studentportfolio';
import StudentAnswers from './studentanswers';
import SkillRanking from '../skillranking';
import SkillProgress from '../skillprogress';

import '../../styles/studentprofile.scss';
import { IoRibbonOutline } from 'react-icons/io5';
import CollaborationIcon from '../../assets/img/Collaboration.png';
import CreativityIcon from '../../assets/img/Creativity.png';
import SocialIcon from '../../assets/img/Life-Social-Skills.png';
import CommunicationIcon from '../../assets/img/Communication.png';
import ProblemIcon from '../../assets/img/ProblemSolving.png';
import StudentProfileImage from '../../assets/img/student-profile.png'
import AwardBadge from '../../assets/img/award-badge.png'


class StudentProfile extends Component {

	constructor() {
		super()
		this.state = {
			location: {},
			examples: {},
			sequence: {},
			screen: '',
			badge: null
		}
	}

	componentDidMount() {
		userService.getuserSequence(this.props.student.username).then(sequence => this.setState({ sequence }))
		userService.getUserProfile(this.props.student.username).then(res => {
			const data = res.location
			let { location } = this.state
			data.forEach(item => {
				location[item.skillid] = {
					phase: item.phase,
					position: item.position
				}
				this.setState({ location })
				userService.getUserImagesCount(this.props.student.username, item.skillid).then(count => {
					let { examples } = this.state
					examples[item.skillid] = count
					this.setState({ examples })
				})
			})
		})
	}

	/*
	 * TODO: Get rid of hard coded skills from app
	 */
	getSkillIcon = (skillid) => {
		switch(skillid) {
			case 1: return CollaborationIcon
			case 2: return CreativityIcon
			case 3: return ProblemIcon
			case 4: return SocialIcon
			case 5: return CommunicationIcon
			default: return null
		}
	}

	/*
	 * TODO: Get rid of hard coded skills from app
	 */
	getSkillText = (skillid) => {
		switch(skillid) {
			case 1: return 'Collaboration'
			case 2: return 'Creativity'
			case 3: return 'Problem Solving'
			case 4: return 'Social'
			case 5: return 'Communication'
			default: return 'Unknown skill'
		}
	}

	/*
	 * TODO: Get rid of hard coded skills from app
	 */
	getSkillName = (skillid) => {
		switch(skillid) {
			case 1: return 'collaboration'
			case 2: return 'creativity'
			case 3: return 'problem-solving'
			case 4: return 'life/social'
			case 5: return 'communication'
		}
	}

	getTotalActivities = level => {
		if (!!this.state.sequence[level]) {
			return this.state.sequence[level].filter(step => step.type !== 1).length
		} else return '-'
	}
	
	getCompletedActivities = (level, skillid) => {
		if (!!this.state.sequence[level] && !!this.state.location[skillid]) {
			return this.state.sequence[level].slice(0, this.state.location[skillid].position).filter(step => step.type !== 1).length
		} else return '-'
	}

	navigateTo = screen => this.setState({ screen })

	openAwardBadgeDialog = badge => {
		if (!!badge) {
			this.setState({ badge, screen: 'awardBadge' })
		}
	}

	awardBadge = badge => {
		if (!!badge) {
			this.props.awardBadge(badge).then(() => this.setState({ screen: 'progress' }))
		}
	}

	getSkill = skillid => {
		const index = this.props.skills.map(skill => skill._id).indexOf(skillid)
		if (index > -1) return this.props.skills[index].title
		else return ''
	}

	refreshUI = () => {
		this.navigateTo('progress')
		this.setState({ badge: null })
	}

	getContentByState = () => {
		const { t } = this.props;
		switch (this.state.screen) {
			case 'portfolio': return <StudentPortfolio 
				student={this.props.student}
				skills={this.props.skills}
				skillid={!!this.state.badge && this.state.badge.skillid}
				level={!!this.state.badge && this.state.badge.phase}
				goBack={this.refreshUI} />
			case 'allAnswers': return <StudentAnswers 
				student={this.props.student}
				skills={this.props.skills}
				sequence={this.state.sequence}
				userLocation={this.state.location}
				skillid={!!this.state.badge && this.state.badge.skillid}
				level={!!this.state.badge && this.state.badge.phase}
				goBack={this.refreshUI} />
			case 'awardBadge': return <>
				<div className='back' onClick={this.navigateTo}>&lt; {t('back')}</div>
				<div className="award">
						<div className="content d-flex flex-column">
							<div className="image">
								<img src={AwardBadge} alt="Award badge" />
								<div className="text">{this.getSkill(this.state.badge.skillid)} - Level {this.state.badge.phase}</div>
							</div>
							<div className="text">Award {this.props.student.firstname} with the Level {this.state.badge.phase} {this.getSkill(this.state.badge.skillid)} Badge</div>
							<div className="actions">
								<div className="primary-button award" onClick={() => this.awardBadge(this.state.badge)}>Award</div>
								<div className="d-flex flex-row justify-content-between review">
									<div className="secondary-button answers" onClick={() => this.navigateTo('allAnswers')}>All Answers</div>
									<div className="secondary-button portfolio" onClick={() => this.navigateTo('portfolio')}>Portfolio</div>
								</div>
							</div>
						</div>
				</div>
			</>
			case 'progress': default:
				const { skills, tags } = this.props
				skills.map(skill => {
					skill.tags = tags.filter(tag => tag.skillid === skill._id)
					return skill
				})
				return <>
					<div className='back' onClick={this.props.navigateTo}>&lt; {t('back')}</div>
						<div className="intro container-fluid row">
							<div className="body col">
								<div className="header">{t('studentprofile')}</div>
								<div className="text">{t('getaninsight')}</div>
							</div>
							<div className="image col-5">
								<img src={StudentProfileImage} alt="Student profile"/>
							</div>
						</div>
					{
						!!this.props.badges && this.props.badges.map(badge => <div className="skill d-flex flex-row justify-content-between" key={badge.skillid} >
										<div className="icon">
											<img src={this.getSkillIcon(badge.skillid)} alt={this.getSkillText(badge.skillid)} />
											<div className="text">{t(this.getSkillName(badge.skillid))}</div>
										</div>
										<div className="location box-shadow">
											<div className="level">{t('level')} {!!this.state.location[badge.skillid] && this.state.location[badge.skillid].phase}</div>
											<div className="status">
												<ProgressMarker
													active={this.getCompletedActivities(badge.phase, badge.skillid)}
													total={this.getTotalActivities(badge.phase)} />
											</div>
											<div className="completed">{this.getCompletedActivities(badge.phase, badge.skillid)}/{this.getTotalActivities(badge.phase)} {t('activitiescompleted')}
											</div>
										</div>
										<div className="examples box-shadow">
											<div className="quantity">{this.state.examples[badge.skillid]}</div>
											<div className="text">{t('examples')}</div>
										</div>
										<div className={`badge box-shadow ${ badge.status === 2 && 'requested'}`}>
											<div className="icon"><IoRibbonOutline /> {t('lvl')}. {badge.phase}</div>
											<div className={`award ${ badge.status === 2 ? 'primary-button' : 'disabled-button'}`} onClick={() => this.openAwardBadgeDialog(badge)}>{t('award')}</div>
										</div>
									</div>
						)
					}
					<div className="extra">
						<SkillRanking skills={skills} />
						<SkillProgress skills={skills} columns={7} />
					</div>
					<div className="actions d-flex flex-row justify-content-around">
						<div className="secondary-button" onClick={() => this.navigateTo('allAnswers')}>{t('allanswers')}</div>
						<div className="secondary-button" onClick={() => this.navigateTo('portfolio')}>{t('portfolio')}</div>
					</div>
				</>
		}
	}

	render() {
		return <div id="studentprofile-component">
			<this.getContentByState />
		</div>
	}
}

export default withTranslation()(StudentProfile);