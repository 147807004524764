import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import userService from '../../services/user.service';
import taskService from '../../services/task.service.';

import SkillMenu from '../skillmenu';

import '../../styles/studentanswers.scss';
import StudentAnswersImage from '../../assets/img/answers.png';


class StudentAnswers extends Component {

	TASKS_PER_PAGE = 3

	constructor(props) {
		super(props)
		this.state = {
			answers: [],
			loadingData: false,
			tasks: {},
			displayIndex: 0,
			selectedSkill: props.skills.length > 0 ? props.skills[0]._id : 1,
			selectedLevel: 1,
			isFirstLoad: true
		}
	}

	componentDidMount() {
		const { skillid, level } = this.props
		this.setState({
			selectedSkill: !!skillid ? skillid : this.state.selectedSkill,
			selectedLevel: !!level ? level : this.state.selectedLevel
		}, () => this.loadAnswers(this.state.selectedSkill, this.state.selectedLevel))
	}

	getChallengeByAnswer = answer => {
		const task = this.state.tasks[answer.taskid]
		const skillIndex = this.props.skills.map(skill => skill._id).indexOf(answer.skillid)
		if(!!task && skillIndex > -1) {
			const step = this.props.sequence[answer.phase][answer.stepid - 1]
			if (!!step && step.dependson > 0) {
				const dependsOn = this.props.sequence[answer.phase][step.dependson - 1]
				if (!!dependsOn) {
					let dependsOnTaskID = 0
					if (Array.isArray(dependsOn.taskref)) {
						dependsOnTaskID = dependsOn.taskref.find(ref => ref.skillid === answer.skillid)
						if (!!dependsOnTaskID) dependsOnTaskID = dependsOnTaskID.taskid
					} else {
						dependsOnTaskID = dependsOn.taskref
					}
					if (dependsOnTaskID > 0) {
						const depAnswer = this.state.answers
							.filter(ans => ans.stepid <= dependsOn.sequence)
							.sort((a, b) => a.stepid < b.stepid)
							.find(ans => ans.phase === step.phase &&
								ans.skillid === answer.skillid &&
								ans.stepid === step.dependson)
						const depTask = this.state.tasks[dependsOnTaskID]

						let tempText = ''
						if (!!depTask) {
							switch (depTask.type) {
								case 1:
									const ans = depTask.body.find(a => !!depAnswer && a.tagid === +depAnswer.answers[0])
									if (!!ans) tempText = ans.tag
									break
								case 3: case 4:
									tempText = !!depAnswer ? depAnswer.answers[0] : ''
									break
								default:
							}
							if (tempText !== '') {
								task.title = task.title.replace('[Challenge2_Selection]', tempText)
								task.title = task.title.replace('[Rating_Selected]', tempText)
								task.title = task.title.replace('[Dimension_Selected]', tempText)
								task.title = task.title.replace('[Dimension_Selection]', tempText)
								task.title = task.title.replace('[Outcome_Selected]', '')
							}
						}
					}
				}
			}
			if (!!this.props.student) task.title = task.title.replace('[Student_Name]', this.props.student.firstname)
			// if( this.props.i18n.language != 'en') {

			// 	// just a placeholder
			// 	var lang = "english";

			// 	if( this.props.i18n.language === 'el' ) {
			// 		lang = "greek";
			// 	}
			// 	else if( this.props.langauge === 'sv' ) {
			// 		lang = "swedish";
			// 	}

			// 	const translation = task.translation.find( ({language}) => language === lang );
			// 	task.title = translation.title;
			// }

			task.title = task.title.replace('[Skill_Tagged]', this.props.t(this.props.skills[skillIndex].title.toLowerCase()))
			return task.title
		} else return ''
	}

	getSkillText = (skillid) => {
		switch(skillid) {
			case 1: return 'collaboration'
			case 2: return 'creativity'
			case 3: return 'problem-solving'
			case 4: return 'life/social'
			case 5: return 'communication'
			default: return 'Unknown skill'
		}
	}

	loadAnswers = (skillid, phase = 1) => {
		this.setState({
			answers: [],
			loadingData: true,
			selectedSkill: skillid,
			selectedLevel: !!this.state.isFirstLoad ? this.props.level : phase,
			displayIndex: 0,
			isFirstLoad: false
		})
		if (!!this.props.student) return userService.getAnswersBySkillLevel(this.props.student.username, skillid, phase).then(answers => {
			const { sequence, userLocation } = this.props
			const skillSequence = sequence[userLocation[skillid].phase]
			const tasks2Remove = skillSequence.filter(step => step.type === 3).map(step => step.taskref).filter((step, index, array) => array.indexOf(step) === index)
			answers = answers.filter(answer => !tasks2Remove.includes(answer.taskid))
			const taskIDs = answers.map(answer => answer.taskid).filter((taskid, index, array) => array.indexOf(taskid) === index)
			taskIDs.forEach(taskid => {
				if (taskid > 0 && !this.state.tasks[taskid]) taskService.getTask(taskid).then(task => {
					const { tasks } = this.state
					tasks[task._id] = task
					this.setState({ tasks })
				})
			})
			this.setState({ answers, loadingData: false })
		})
	}

	changeSkill = skillid => this.loadAnswers(skillid)

	changeLevel = level => {
		if (!isNaN(level)) level = +level
		else level = 1
		this.loadAnswers(this.state.selectedSkill, level)
	}

	changeStep = (stepIndex) => this.setState({ displayIndex: stepIndex})

	previousStep = () => this.changeStep(this.state.displayIndex - 1)
	nextStep = () => this.changeStep(this.state.displayIndex + 1)

	printScale = (total, active) => {
		const array = []
		for (let i = 1; i <= total; i++) array.push(i)
		return <div className='rating-answer d-flex flex-row'>{
			array.map(mark => <div className={`mark ${ mark <= active && 'active'}`}></div>)
		}</div>
	}

	printAnswer = answer => {
		let r = []
		const task = this.state.tasks[answer.taskid]
		if (!answer) return []
		const { answers } = answer
		if (!answers || answers.length < 1) return []
		if (!!task) {
			switch (task.type) {
				case 1:
					if (!!isNaN(answers[0])) return []
					const answerIndex = task.body.map(ans => ans.tagid).indexOf(+answers[0])
					return [task.body[answerIndex].tag]
				case 2:
					answers.forEach(ans => {
						if (!isNaN(ans)) {
							const answerIndex = task.body.map(ans => ans.tagid).indexOf(+ans)
							r.push(task.body[answerIndex].tag)
						}
					})
					return r
				case 3: case 6:
					return [answers[0]]
				case 4:
					if (!!isNaN(answers[0])) return []
					return [this.printScale(task.body.length > 0 ? task.body.length : 10, +answers[0])]
				case 7:
					if (answers.length !== task.body.length) return []
					answers.forEach((ans, index) => {
						r.push('- ' + task.body[index].tag)
						r.push(ans)
					})
					return r
				case 8:
					if (answers.length !== task.body.length) return []
					answers.forEach((ans, index) => {
						r.push(task.body[index].tag)
						r.push(this.printScale(4, !isNaN(ans) ? ans : 0))
					})
					return r
				case 5: case 11: default: return []
			}
		} else {
			if (answers.length < 1) return []
			else return []
		}
	}

	createPaginationSteps = () => {
		const { answers } = this.state
		let r = []
		for (let i = 0; i < answers.length / this.TASKS_PER_PAGE; i++)
			r.push(<div className={`step ${this.state.displayIndex === i ? 'active' : ''}`} key={i} onClick={() => this.changeStep(i)}>{i + 1}</div>)
		return r
	}

	render() {
		const { t } = this.props;
		const answers = this.state.answers.slice(this.TASKS_PER_PAGE * this.state.displayIndex, this.TASKS_PER_PAGE * (this.state.displayIndex + 1))
		return <div id="studentanswers-component">
			<div className='back' onClick={this.props.goBack}>&lt; Back</div>
			<div className="intro container-fluid row">
							<div className="body col">
								<div className="header">Answers</div>
								<div className="text">Check what students are answering to their activities for each skill</div>
							</div>
							<div className="image col-5">
								<img src={StudentAnswersImage} alt="Student answers"/>
							</div>
						</div>
			<div className="main-content d-flex flex-row">
				<SkillMenu
					skills={this.props.skills}
					activeSkill={this.state.selectedSkill}
					onSkillSelected={this.changeSkill} />
				<div className="content flex-grow-1">
					<div className="level-selector">
						<select name="level" value={this.state.selectedLevel} onChange={e => this.changeLevel(e.target.value)}>
							<option value="-1">{t('chooselevel')}</option>
							<option value="1">{t('level')} 1</option>
							<option value="2">{t('level')} 2</option>
							<option value="3">{t('level')} 3</option>
							<option value="4">{t('level')} 4</option>
							<option value="5">{t('level5')}</option>
						</select>
					</div>
					<div className="answers">
						{ answers.map((answer, index) => <div className='answer-entry d-flex flex-row'>
							<div className="number">{this.TASKS_PER_PAGE * this.state.displayIndex + index}</div>
							<div className={`content ${index > 0 && 'with-border'}`}>
								<div className="challenge">{this.getChallengeByAnswer(answer)}</div>
								{this.printAnswer(answer).map(line => <div className="answer">{line}</div>)}
							</div>
						</div>)}
					</div>
					{ this.state.answers.length > this.TASKS_PER_PAGE &&
						<div className="pagination">
							<div className="step previous" onClick={this.previousStep}><IoIosArrowBack /></div>
							<this.createPaginationSteps />
							<div className="step next" onClick={this.nextStep}><IoIosArrowForward /></div>
						</div>
					}
				</div>
			</div>
			</div>
	}
}

export default withTranslation()(StudentAnswers);