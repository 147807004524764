import apiService from './api.service';

class TaskService {

    API_ENDPOINT = 'task'

    getTask(taskid) {
        return apiService.get(`${this.API_ENDPOINT}/${taskid}`).then(data => data.data.task)
    }

}

export default new TaskService();



