import apiService from './api.service';

class BadgesService {

    API_ENDPOINT = 'user/badge'

    getBadgesByUser(username) {
        return apiService.get(`${this.API_ENDPOINT}/username/${username}`).then(data => data.data.badges)
    }

    getBadgeData(badgeid) {
        return apiService.get(`${this.API_ENDPOINT}/badge/${badgeid}`).then(data => data.data.badge)
    }

    getBadgeBySkillidNPhase(skillid, phase) {
        return apiService.get(`${this.API_ENDPOINT}/skillid/${skillid}/phase/${phase}`).then(data => data.data.badge)
    }

    awardBadge(username, skillid, phase, notes) {
        return this.getBadgeBySkillidNPhase(skillid, phase).then(badge => {
            if (!!badge) apiService.put(this.API_ENDPOINT, { username, badgeid: badge._id, status: 3, notes })
        })
    }

    async createBadges(username, skills, levels) {
        let badges = []
        for (let i = 0; i < skills.length; i++) {
            for (let j = 0; j < levels.length; j++) {
                let badge = await this.getBadgeBySkillidNPhase(skills[i], levels[j])
                badges.push({ badgeid: badge._id, status: 1, notes: ''})
            }
        }
        return apiService.post(`${this.API_ENDPOINT}/badges`, { username, badges})
    }
}

export default new BadgesService();



