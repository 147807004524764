import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';

import '../styles/sidenavigation.scss'
import { VscHome } from 'react-icons/vsc'
import { GoPlus } from 'react-icons/go'
import { ImExit } from 'react-icons/im'
import { SiAirtable } from 'react-icons/si'
import { AiFillPrinter } from 'react-icons/ai';
import { BsGear } from 'react-icons/bs';

class SideNavigation extends Component {

	constructor(props) {
        super(props);
    }
	
	render() {
		const { t } = this.props;
		return <div id="sidenavigation-component" className='d-flex flex-column'>
			<div className="header" onClick={() => this.props.navigateTo()}>
				<div className="title">ASSESSMAKE21</div>
				<div className="description">{t('educatorsdashboard')}</div>
			</div>
			<div className="actions-list flex-grow-1">
				<div className={`action ${ this.props.location === 'home' ? 'active' : ''}`} onClick={() => this.props.navigateTo()} key='home'><VscHome/> {t('home')}</div>
				<div className={`action ${ this.props.location === 'classroom-wizard' ? 'active' : ''}`} onClick={() => this.props.navigateTo('classroom-wizard')} key='newclass'><GoPlus/> {t('createnewclassroom')}</div>
				<Accordion>
				{ this.props.classrooms.map((classroom, index) =>
					<Card key={classroom._id} className={`entry ${(!!this.props.activeClassroom && classroom._id === this.props.activeClassroom._id) ? 'active' : ''}`} >
						<Accordion.Toggle className='d-flex flex-row justify-content-between align-items-center' as={Card.Header} eventKey={index + 1}>
							<div onClick={() => this.props.navigateTo('classroom-profile', { classroomToDisplay: classroom, childStep: 'studentGrid' })}><SiAirtable/>  {classroom.name}</div>
							<div className='arrow'><IoIosArrowDown /></div>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey={index + 1}>
							<Card.Body>
								<div onClick={() => this.props.navigateTo('classroom-profile', { classroomToDisplay: classroom, childStep: 'addStudents' })}><GoPlus />  {t('addstudent')}</div>
								<div onClick={() => this.props.navigateTo('classroom-profile', { classroomToDisplay: classroom, childStep: 'classroomSettings' })}><BsGear />  {t('settings')}</div>
								<div onClick={() => this.props.navigateTo('classroom-profile', { classroomToDisplay: classroom, childStep: 'studentCodes' })}><AiFillPrinter />  {t('credentials')}</div>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				)}
				</Accordion>
			</div>
			<div className="logout" onClick={this.props.onLogout}>
				<ImExit/><br/>
				{t('logout')}
			</div>
		</div>
	}
}

export default withTranslation()(SideNavigation)