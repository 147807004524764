import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ClassroomGrid from '../classroomgrid';

import '../../styles/home.scss'
import HomeImage from '../../assets/img/home.png'

class Home extends Component {
	constructor(props) {
        super(props);
      
    }
  render() {
	const { t } = this.props;
    return (<div id='home-component'>
		<div className="header">{t('educatorsdashboard')}</div>
		<div className="intro container-fluid row">
			<div className="body col">
				<div className="header">{t('welcomeback')}, {this.props.user.firstname || ''}!</div>
				<div className="text">{t('manage_classrooms')}</div>
			</div>
			<div className="image col-5">
				<img src={HomeImage} alt="Homepage"/>
			</div>
		</div>
		<div className="classrooms">
			<p className='header'>{t('myclassrooms')}</p>
			<ClassroomGrid classrooms={this.props.classrooms} navigateTo={this.props.navigateTo} />
			</div>
    </div>);
  }
}

export default  withTranslation()(Home);
