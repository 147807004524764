import bcryptjs from "bcryptjs";

import apiService from './api.service';
import userService from "./user.service";

class ClassroomService {

    API_ENDPOINT = 'classroom'

    getClassrooms(username) {
        return apiService.get(`user/${this.API_ENDPOINT}/${username}`)
            .then(({data}) => !!data.classrooms ? data.classrooms : [])
    }
    
	getClassroomActivity(classroomID) {
		return apiService.get(`classroomactivity/${classroomID}`)
			.then(res => res.data.useractivity)
	}

    createClassroom(username, studentNames, name, duration, approachtype, toolsref, language, activitymodelref) {
        const student_members = []
        studentNames.forEach(studentName => {
            const student = {}
            studentName = studentName.trim()
            const splitName = studentName.split(' ')
            student.firstname = splitName[0]
            student.lastname = splitName.length > 1 ? splitName.slice(1).join(' ') : ''
            student.username = this.generateUsername(studentName)
            student.enabled = true
            student.roles = [{'name': 'Learner'}]
            student.islogged = false
            student.accessCode = this.generateAccessCode()
            student_members.push(student)
        })
        return apiService.post(`${this.API_ENDPOINT}`, {
                username,
                student_members: student_members.map(student => student.username),
                name,
                duration,
                approachtype,
                toolsref,
                language,
                activitymodelref
            })
            .then(({data}) => {
                data._id = data.id
                const promises = []
                student_members.forEach(({ username, firstname, lastname, accessCode }) => promises.push(userService.createUser(username, firstname, lastname, accessCode, data.id)))
                return Promise.all(promises).then(() => {
                    return {
                        classroom: {
                            id: data.id,
                            _id: data._id,
                            username,
                            student_members: student_members.map(student => student.username),
                            name,
                            duration,
                            approachtype,
                            toolsref,
                            language,
                            activitymodelref
                        },
                        codes: student_members.map(student => {
                            return {
                                username: student.username,
                                firstname: student.firstname,
                                lastname: student.lastname,
                                accessCode: student.accessCode
                            }
                        })
                    }
                })
            })
    }

    generateAccessCode = (accessCodeLength = 6) => {
        let tempAccessCode = [];
        const availableCharacters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = availableCharacters.length;
        for (let i = 0; i < accessCodeLength; i++ ) {
            tempAccessCode.push(availableCharacters.charAt(Math.floor(Math.random() * charactersLength)))
        }
        return tempAccessCode.join('')
    }

    generateUsername = (name) => {
        return bcryptjs.hashSync(name, 10).replaceAll('/','0').replaceAll('.','9').toLowerCase()
    }

    updateClassroom = (classroom) => {
        return apiService.put(this.API_ENDPOINT, classroom).then(res => res.data)
    }

    removeStudentFromClassroom = (classroomid, studentusername) => {
        return Promise.all([
            apiService.delete(`${this.API_ENDPOINT}/${classroomid}/student/${studentusername}`).then(res => res.data),
            userService.disableUser(studentusername)
        ])
    }

    addStudentToClassroom = (classroomid, studentusername) => {
        return apiService.post(`${this.API_ENDPOINT}/${classroomid}/student/${studentusername}`).then(res => res.data)
    }

    getClassroomTags = classroomid => {
        return apiService.get(`${this.API_ENDPOINT}/${classroomid}/tags`).then(res => res.data.tags)
    }

    getClassroomImages = (classroomid, count) => {
        return apiService.get(`${this.API_ENDPOINT}/${classroomid}/images/count/${count}`).then(res => res.data.images)
    }
}

export default new ClassroomService();



