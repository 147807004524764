import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import '../styles/classroomgrid.scss';
import Pencil from '../assets/img/pencil.png';

class ClassroomGrid extends Component {

	constructor(props) {
        super(props);
    }

	render() {
		const { t } = this.props;
		return <div id="classroomgrid-component" className='container-fluid row'>
			{ this.props.classrooms.map(classroom => {
				return <div key={classroom._id} className="classroom-container col-4">
					<div className="classroom d-flex flex-column align-items-center box-shadow"
						onClick={() => this.props.navigateTo('classroom-profile', { classroomToDisplay: classroom })}>
						<img src={ Pencil } alt="pencil"/>
						<span className='name'>{classroom.name}</span>
						<span className='students'>{ `${classroom.student_members.length} ${classroom.student_members.length === 1 ? t('student') : t('students')}`}</span>
					</div>
				</div>
			})}
		</div>
	}
}

export default withTranslation()(ClassroomGrid);