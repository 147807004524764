import React, { Component } from 'react';

import '../styles/progressmarker.scss';

class ProgressMarker extends Component {

	render() {
		let content = []
		for (let i = 0; i < this.props.total; i++) {
			content.push(
				<div className={`checkpoint ${i < this.props.active && 'completed'}`} key={i}></div>
			)
		}
		return <div id="progressmarker-component" className='d-flex flex-row justify-content-between'>
			{content}
		</div>
	}
}

export default ProgressMarker;