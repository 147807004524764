import apiService from './api.service';
import bcryptjs from 'bcryptjs';

class AuthService {

    API_ENDPOINT = 'auth'
    CLIENT_SALT = '$2a$10$60uX4qjyRIgMz6A/XgNtZu'
    TEACHER_KEY = 'our_super_secret_key'

    login(email, password) {
        password = bcryptjs.hashSync(password, this.CLIENT_SALT)
        return apiService.post(`${this.API_ENDPOINT}/signin`, {email, password}, false)
            .then(res => res.data)
    }

}

export default new AuthService();



