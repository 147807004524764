import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import '../styles/studentgrid.scss';
import StudentGridBanner from '../assets/img/student-grid.png';
import { GoPlus } from 'react-icons/go';
import { FaSearch, FaExclamation } from 'react-icons/fa';
import { IoIosRibbon } from 'react-icons/io';

class StudentGrid extends Component {

	constructor(props) {
		super(props)
		this.state = {
			classroom: props.classroom,
			students: props.students
		}
	}

	componentDidMount() {
		this.handleFilter('')
	}

	componentDidUpdate(prevProps) {
		const prevStudents = prevProps.students.map(st => st._id).sort((a, b) => a - b)
		const propStudents = this.props.students.map(st => st._id).sort((a, b) => a - b)

		if (prevStudents.toString() !== propStudents.toString()) this.handleFilter('')
	}

	handleFilter = text => {
		if (text === '') this.setState({ students: this.props.students })
		else {
			let { students } = this.props
			students = students.filter(student =>
				`${student.firstname} ${student.lastname}`.toLowerCase().includes(text.toLowerCase()))
			this.setState({ students })
		}
	}

	render() {
		const { t } = this.props;
		return <div id="studentgrid-component" className='container-fluid row'>
			<div className='back' onClick={this.props.navigateToApp}>&lt; {t('back')}</div>
			<div className="intro container-fluid row">
				<div className="body col">
					<div className="header">{t('classroomoverview')}</div>
					<div className="text">{t('seeanoverview')} {t('youcancheck')}</div>
				</div>
				<div className="image col-5">
					<img src={StudentGridBanner} alt="Student overview"/>
				</div>
			</div>
			<div className="row toolbar d-flex justify-content-between">
				<div className="new-student" onClick={() => this.props.navigateTo('addStudents')}><GoPlus /> {t('addanewstudent')}</div>
				<div className="search"><input type="text" placeholder={t('searchstudents')} onChange={e => this.handleFilter(e.target.value)}/> <FaSearch/></div>
			</div>
			<div className="students container-fluid row">
				{ this.state.students.map(student => (
					<div key={student.username} className='content col-4'>
						<div className="student box-shadow" onClick={() => this.props.setDisplayStudent(student.username)}>
							<div className="name">{`${student.firstname} ${student.lastname}`}</div>
							<div className="access-code">{student.accessCode.toUpperCase().split('').join('-')}</div>
							<div className="overview row">
								<div className={`badges col-6 row container-fluid ${ !!student.hasPendingBadge && 'pending'}`}>
										<div className="icon col-4"><IoIosRibbon /></div>
										<div className="text col-8">{t('requestedabadge')}</div>
								</div>
								<div className={`status col-6 row container-fluid ${ student.active && 'pending'}`} >
									<div className="icon col-4"><FaExclamation /></div>
									<div className="text col-8">{t('studentinactive')}</div>
								</div>
							</div>
							<div className="tools">
								<div className="tool">- Computational Thinking</div>
								<div className="tool">- Arduino</div>
							</div>
						</div>
					</div>
				)) }
			</div>
		</div>
	}
}

export default withTranslation()(StudentGrid);