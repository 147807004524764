import React, { Component } from 'react';

import '../../styles/languageselection.scss';
import { BsToggleOff } from 'react-icons/bs';
import { BsToggleOn } from 'react-icons/bs';
import SelectLanguage from '../../assets/img/select-language.png'
import { withTranslation } from 'react-i18next';
class LanguageSelection extends Component {
	constructor(props) {
        super(props);
    
    }
	handleValueChange = (e) => {
		this.props.updateData(e.target.value)
	}

	render() {
		const { t } = this.props;
		return <div id="languageselection-component">
			<div className="header">{t('chooselanguage')}</div>
			<div className='back' onClick={this.props.goPrevious}>&lt; {t('back')}</div>
			<div className="intro container-fluid row">
				<div className="body col">
					<div className="header">{t('language')}</div>
					<div className="text">{t('chooseyourclasslang')}</div>
				</div>
				<div className="image col-5">
					<img src={SelectLanguage} alt="Choose language"/>
				</div>
			</div>
			<div className="container-fluid col languages">
				{
					this.props.options && this.props.options.map(option =>
						<div className="col" key={option.value}>
							<input type="radio" name="language" value={option.value} id={option.value} onChange={this.handleValueChange}/>
							<label className={`language box-shadow ${this.props.language === option.value ? 'active':''}`} htmlFor={option.value}>
								<div className="d-flex flex-row justify-content-between title">
									<div className="text">{t(option.text)}</div>
									{ this.props.language === option.value ? <BsToggleOn/> : <BsToggleOff/> }
								</div>
							</label>
						</div>
					)
				}
			</div>
			<div className="actions d-flex flex-row justify-content-end">
				<button className='secondary-button' onClick={this.props.goPrevious}>{t('cancel')}</button>
				<button className={ this.props.language !== null ? 'primary-button' : 'disabled-button' } onClick={this.props.goNext}>{t('next')}</button>
			</div>
		</div>
	}
}

export default withTranslation()(LanguageSelection);