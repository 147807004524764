import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import '../../styles/wizardsummary.scss';
import ClassroomSummary from '../../assets/img/classroom-summary.png'

class WizardSummary extends Component {

	constructor(props) {
        super(props);
    }

	componentDidMount() {
		if (!!this.props.reachedSummary) this.props.reachedSummary()
	}

	handleNameUpdate = (event) => {
		this.props.updateData(event.target.value)
	}

	render() {
		const { t } = this.props;
		let duration = this.props.data.duration
		let approach = this.props.data.approachtype
		if (approach !== null) approach = this.props.approachOptions.find(item => item.value === approach)
		if (duration !== null) duration = this.props.durationOptions.find(item => item.value === duration)

		return <div id="wizardsummary-component">
			<div className="header">{t('finaldetails')}</div>
			<div className='back' onClick={this.props.goPrevious}>&lt; {t('back')}</div>
			<div className="intro container-fluid row">
				<div className="body col">
					<div className="header">{t('almostthere')}</div>
					<div className="text">{t('nameyourclassroom')}</div>
				</div>
				<div className="image col-5">
					<img src={ClassroomSummary} alt="Final details"/>
				</div>
			</div>
			<div className="container-fluid col">
				<div className="row details">
					<div className="col">
						<div className="technologies intro d-flex flex-row justify-content-between">
							<div className="body flex-grow-1">
								<div className="header">{t('tech')}</div>
								<div className="text">
									<b>{t('fabtech')}</b><br/>
									{t('3dprinter')}<br/>
									{t('lasercutterr')}<br/>
									{t('3dpen')}<br/>
									{t('cncrouter')}<br/>
									<b>{t('microprocessor')}</b><br/>
									{t('arduino')}<br/>
									{t('rasppi')}<br/>
									{t('microbit')}<br/>
									<b>{t('other')}</b><br/>
									{t('computer')}<br/>

								</div>
							</div>
							<div className="change align-self-end">
								{/**
								 * This is commented out since it is not implemented yet
								 * <button onClick={() => this.props.go('')}>{t('change')}</button>
								 * */}
							</div>
						</div>
					</div>
					<div className="col">
						{ duration !== null && <div className="duration intro d-flex flex-row justify-content-between">
							<div className="body flex-grow-1">
								<div className="header">{t(duration.title)}</div>
								<div className="text">{t(duration.description)}</div>
							</div>
							<div className="change align-self-end">
								{ !this.props.data._id && <button onClick={() => this.props.go('durationSelection')}>{t('change')}</button>}
							</div>
						</div>}
						{ approach !== null && <div className="approach intro d-flex flex-row justify-content-between">
							<div className="body flex-grow-1">
								<div className="header">{t(approach.title)}</div>
								<div className="text">{t(approach.description)}</div>
							</div>
							<div className="change align-self-end">
								<button onClick={() => this.props.go('approachSelection')}>{t('change')}</button>
							</div>
						</div>}
					</div>
				</div>
				<div className="row">
					<div className="name">
						<div className="header">{t('classroomname')}</div>
						<input type="text" className='box-shadow' value={this.props.data.name} placeholder={t('nameyourclassroomhere')} onChange={this.handleNameUpdate} />
					</div>
				</div>
			</div>
			<div className="actions d-flex flex-row justify-content-end">
				<button className='secondary-button' onClick={this.props.goPrevious}>{t('cancel')}</button>
				<button className={ this.props.data.name !== '' ? 'primary-button' : 'disabled-button' } onClick={this.props.saveClassroom}>{t('next')}</button>
			</div>
		</div>
	}
}

export default withTranslation()(WizardSummary);