import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import userService from '../../services/user.service';
import classroomService from '../../services/classroom.service';
import badgesService from '../../services/badges.service';

import StudentGrid from '../studentgrid';
import AddStudents from '../addstudents';
import CodesDisplay from './codesdisplay';
import StudentProfile from './studentprofile';
import ClassroomWizard from './classroomwizard';
import SkillRanking from '../skillranking'
import SkillProgress from '../skillprogress';
import ExamplesCarousel from '../examplescarousel'

import '../../styles/classroomprofile.scss'
import { FiEdit3 } from 'react-icons/fi';

class ClassroomProfile extends Component {

	IMAGES_IN_CAROUSEL = 8

	constructor(props) {
		super(props)
		this.state = {
			currentStep: '',
			students: [],
			displayStudent: null,
			studentsBadges: {},
			enableNavigation: false,
			tags: [],
			classroomExamples: [],
			classroomActivity: []
		}
	}

	componentDidMount() {
		this.loadClassroomData(this.props.classroom)
		if (!!this.props.step) this.navigateTo(this.props.step)

	}

	componentDidUpdate(prevProps) {
		if (prevProps.classroom !== this.props.classroom) {
			this.setState({ students: [], tags: [], classroomExamples: [] })
			this.loadClassroomData(this.props.classroom)
		}
		if (prevProps.step !== this.props.step) {
			this.navigateTo(this.props.step)
		}
	}

	loadClassroomData = classroom => {
		if (!classroom) return
		/**
		 * Here I'm loading all the badges data and all the badges per user. When everything
		 * is loaded I filter all badges to have only the ones required for the app to work.
		 * 
		 * Only one badge per phase is saved and all the data (required for rendering child
		 * components) is put together into the same object
		 * 
		 * This function tries to achieve something like this (in React state):
		 * studentsBadges: {
		 * 				*studentUsername*: [
		 * 									{"badgeid":1,"status":3,"notes":"","phase":1,"skillid":1},
		 * 									{"badgeid":2,"status":2,"notes":"","phase":2,"skillid":2},
		 * 									{"badgeid":3,"status":1,"notes":"","phase":3,"skillid":3},
		 * 									{"badgeid":4,"status":2,"notes":"","phase":4,"skillid":4},
		 * 									{"badgeid":5,"status":1,"notes":"","phase":5,"skillid":5}
		 * 									]
		 * 				}
		 * 
		 * Without the extra processing we would have all badges for all skills and with badgeid, phase
		 * and skillid placed in different objects
		 * 
		 //PLEASE pay a lot of attention on the names of variables. Some of them are the same but on singular/plural variants
		 */
		let promises = []
		/**
		 * Late addition: We need to load the all the classroom activities so they are available when we
		 * process the students
		 */
		promises.push(classroomService.getClassroomActivity(classroom._id))
		// We need to retrieve badges and student information per student
		classroom.student_members.forEach(student => {
			promises.push(badgesService.getBadgesByUser(student)) // Here we prepare all user badges to be pulled all together at once in the next step
			this.loadStudent(student) // Here we call a function to load student's information
		})
		Promise.all(promises).then(([classroomActivity, ...allStudentsBadges]) => {
			// Once we have all badges for each user, we create the final object (studentsBadges)
			// and start loading it with data.
			const studentsBadges = {}
			allStudentsBadges.forEach((studentData, index) => {
				studentsBadges[classroom.student_members[index]] = studentData
			})
			/**
			 * At this point we have all badges for all skills for all students, but we only have
			 * badge's ID. We still need to retrieve all badges' data. To avoid unnecessary calls,
			 * we filter all badges' ID that are different and then call the API (API calls are put
			 * in the *promises* variable)
			 */
			const discreteBadges = []
			Object.values(studentsBadges).forEach(badges => badges.forEach(badge => {
				if (discreteBadges.findIndex(discreteBadge => discreteBadge.badgeid === badge.badgeid) === -1)
					discreteBadges.push(badge)
			}))
			promises = []
			discreteBadges.forEach(badge => promises.push(badgesService.getBadgeData(badge.badgeid)))
			Promise.all(promises).then(badgesData => {
				/**
				 * Here we have all the data we need; *badgesData* has the information about the badge
				 * itself, and *studentsBadges* has the badge status for each student. Now we have to
				 * put all the data together (for easier processing) and filter which badges we want
				 * to actually save on React state (only one badge per skill)
				 * 
				 * But first, we need to know what skills are being used, since these are not 
				 * fixed or hardcoded. To do this we check all the different skillids we have in
				 * *badgesData*
				 */
				const skills = []
				badgesData.forEach(badge => {
					if (skills.findIndex(skill => skill === badge.skillid) === -1)
						skills.push(badge.skillid)
				})
				Object.entries(studentsBadges).forEach(([username, allBadges]) => {
					/**
					 * Now the hard processing starts. Inside this function we have *username* (of
					 * the student) and *allBadges* (all badges for all skills and phases for one
					 * student). Since we only have badgeid, status and notes in every *allBadges*
					 * entries, we need to add the phase and skillid
					 */
					const userBadges = []
					allBadges.forEach((badge, index) => {
						const badgeData = badgesData.find(badgeData => badgeData._id === badge.badgeid)
						if (!!badgeData) {
							allBadges[index].phase = badgeData.phase
							allBadges[index].skillid = badgeData.skillid
						}
					})
					/**
					 * Once we have all the necessary data we can start filtering by skill. We filter by
					 * skill and sort it by phase and then look for what badge we want:
					 * - If there is a badge with status *2* (requested), we want to show the FIRST one
					 * - If there isn't any badges with status *2*, we want to look for status *1*
					 * - If there is a badge with status *1* (available), we want to show the FIRST one
					 * - If there isn't any badges with status *1*, we want to look for status *3*
					 * - If there is a badge with status *3* (available), we want to show the LAST one
					 * - If there isn't any badges with status *3*, we will use the last badge (max level)
					 */
					skills.forEach(skillid => {
						const filteredBadges = allBadges
							.filter(badge => badge.skillid === skillid)
							.sort((a, b) => a.phase - b.phase)
						let lastBadgeIndex = filteredBadges.findIndex(badge => badge.status === 2)
						if (lastBadgeIndex === -1) {
							lastBadgeIndex = filteredBadges.findIndex(badge => badge.status === 1)
							if (lastBadgeIndex === -1) {
								const tempBadges = filteredBadges.slice().sort((a, b) => b.phase - a.phase)
								lastBadgeIndex = tempBadges.length - 1 - tempBadges.findIndex(badge => badge.status === 3)
								if (lastBadgeIndex === -1) {
									lastBadgeIndex = filteredBadges.length - 1
								}
							}
						}
						userBadges.push(filteredBadges[lastBadgeIndex])
					})
					/**
					 * Badges for this student are ready, so we just save it in React state with student's
					 * username as key
					 */
					const { studentsBadges } = this.state
					studentsBadges[username] = userBadges
					this.setState({ studentsBadges })
					/**
					 * And finally we have to check if there is any badge with status *2* (pending) or if
					 * the user is inactive (one week with no logs) so *StudentGrid* can use and display
					 * the student correctly
					 */
					let { students } = this.state
					if (userBadges.findIndex(badge => badge.status === 2) > -1) {
						const studentIndex = students.findIndex(student => student.username === username)
						if (!!students[studentIndex]) students[studentIndex].hasPendingBadge = true
					}
					students = students.map(student => {
						student.active = this.isStudentActive(student.username)
						return student
					})
					this.setState({ students, classroomActivity })
				})
				/**
				 * When everything is loaded and processed, we enable user to visit student's profile.
				 * This check is necessary to avoid errors, since this is the only place where the badges
				 * are retrieved and processed from the API
				 */
				this.setState({ enableNavigation: true })
			})
		})

		classroomService.getClassroomTags(classroom._id).then(tags => this.setState({ tags }))
		classroomService.getClassroomImages(classroom._id, this.IMAGES_IN_CAROUSEL).then(classroomExamples => this.setState({ classroomExamples }))
	}

	addStudent = (student) => {
		let tempStudents = this.state.students
		tempStudents.push(student)
		this.setState({ students: tempStudents })
	}

	isStudentActive = username => {
		// Filter activity log for only current student's data and sorting it by date of creation
		let filteredActivity = this.state.classroomActivity
			.filter(item => item.username === username)
			.sort((a, b) => a.createdAt - b.createdAt)

		if (filteredActivity.length > 0) {
			const mostRecentActivity = filteredActivity[filteredActivity.length - 1];
			const currentDate = new Date();

			const diffTime = Math.abs(currentDate - new Date( mostRecentActivity.createdAt ));
			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

			if (diffDays < 7) return false
		}
		return true;
	}

	loadStudent = (username) => {
		if (this.state.students.findIndex(student => student.username === username) === -1) {
			userService.getUserByUsername(username).then(student => this.addStudent(student))
		}
	}

	handleAddStudents = students => {
		let { classroom } = this.props
		students.forEach(student => {
			if (classroom.student_members.indexOf(student.username) === -1) {
				userService.createUser(student.username, student.firstname, student.lastname, student.accessCode, classroom._id)
				classroom.student_members.push(student.username)
				this.addStudent(student)
				this.props.updateClassroom(classroom)
			}
		})
		classroomService.updateClassroom(classroom)
	}

	handleStudentDelete = (username) => {
		const { classroom } = this.props
		let tempStudents = classroom.student_members
		tempStudents = tempStudents.filter(student => student !== username)
		classroom.student_members = tempStudents
		classroomService.removeStudentFromClassroom(classroom._id, username).then(([classroom, user]) => {
			let tempCodes = this.state.students
			tempCodes = tempCodes.filter(code => code.username !== username)
			this.setState({ students: tempCodes })
		})
	}

	handleAwardBadge = badge => {
		if (badge.status === 2) {
			return badgesService.awardBadge(this.state.displayStudent, badge.skillid, badge.phase, '').then(x => {
				const { studentsBadges } = this.state
				const studentBadges = studentsBadges[this.state.displayStudent]
				const badgeIndex = studentBadges.findIndex(currentBadge => currentBadge.badgeid === badge.badgeid)
				if (badgeIndex > -1) studentBadges[badgeIndex].status = 3
				studentsBadges[this.state.displayStudent] = studentBadges
				this.setState({ studentsBadges })
			})
		}
	}

	navigateTo = (destination = 'studentGrid') => this.setState({ currentStep: destination, displayStudent: null })

	displayStudentName() {
		if( this.state.displayStudent) {
			const student = this.state.students.find( ({username}) => username === this.state.displayStudent );

			return ` >  ${ !!student ? student.firstname : '' }`
		}
	}

	setDisplayStudent = student => !!this.state.enableNavigation &&this.setState({ displayStudent: student, currentStep: 'studentProfile'})

	getContentByState = () => {
		switch (this.state.currentStep) {
			case 'addStudents':
				return <AddStudents
					classroom={this.props.classroom}
					navigateTo={this.navigateTo}
					addStudents={this.handleAddStudents} />;
			case 'studentCodes':
				return <CodesDisplay
					codes={this.state.students}
					classroom={this.props.classroom}
					handleDeleteStudent={this.handleStudentDelete}
					navigateTo={this.navigateTo} />
			case 'studentProfile':
				return <StudentProfile
					student={this.state.students.find(({username}) => username === this.state.displayStudent)}
					badges={this.state.studentsBadges[this.state.displayStudent]}
					skills={this.props.skills}
					tags={this.state.tags.filter(tag => tag.username === this.state.displayStudent)}
					awardBadge={this.handleAwardBadge}
					loadStudent={this.setDisplayStudent}
					navigateTo={this.navigateTo} />
			case 'classroomSettings':
				return <ClassroomWizard
					navigateTo={this.navigateTo}
					updateClassroom={this.props.updateClassroom}
					classroom={this.props.classroom} />
			case 'studentGrid':
			default:
				const { skills } = this.props
				const { tags } = this.state
				skills.map(skill => {
					skill.tags = tags.filter(tag => tag.skillid === skill._id)
					return skill
				})
				return <>
					<StudentGrid
						classroom={this.props.classroom}
						students={this.state.students}
						setDisplayStudent={this.setDisplayStudent}
						navigateToApp={this.props.navigateTo}
						navigateTo={this.navigateTo} />
						<div className="analysis container-fluid row">
							{ this.state.classroomExamples.length > 0 && <div className="col-6"><ExamplesCarousel
								classroom={this.props.classroom}
								images={this.state.classroomExamples}
								skills={this.props.skills}
								students={this.state.students} /></div>}
							<div className="col-6"><SkillRanking skills={skills} /></div>
						</div>
						<SkillProgress skills={skills} columns={7} />
				</>
		}
	}

	render() {
		const { t } = this.props;
		return <div id="classroomprofile-component">
			<div className="header d-flex flex-row justify-content-between">
				<div className="name"><span>{ !!this.props.classroom && this.props.classroom.name }</span><span>{ this.displayStudentName() }</span> <FiEdit3 /></div>
				<div className="actions">
					<div className="credentials" onClick={() => this.navigateTo('studentCodes')}>{t('logincredentials')}</div>
				</div>
			</div>
			<this.getContentByState />
		</div>
	}
}

export default withTranslation()(ClassroomProfile);