import React, { Component } from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";

import {callAddFont} from '../../assets/Roboto-Regular-normal.js';

import '../../styles/codesdisplay.scss';
import PaperPlane from '../../assets/img/paper-plane.png'
import DeleteStudent from '../../assets/img/delete-student.png'
import { AiFillPrinter } from 'react-icons/ai'
import { withTranslation } from 'react-i18next';

class CodesDisplay extends Component {

	constructor() {
		super()
		this.state = {
			deleteUser: null
		}

		jsPDF.API.events.push(["addFonts", callAddFont]);
	}

	handlePrint = () => {
		const { codes } = this.props
		const doc = new jsPDF({
			orientation: 'l',
			unit: 'px',
			format: 'a4'
		  })

		doc.setFont('Roboto-Regular');

		doc.setFontSize(20)
		const tableRows = [];
		for (let i = 0; i < codes.length; i = i+2) {
			const codeData = [
				`${codes[i].firstname} ${codes[i].lastname}`,
				codes[i].accessCode.toUpperCase().split('').join('-')
			]
			if (!!codes[i+1]) codeData.push(...[
				`${codes[i+1].firstname} ${codes[i+1].lastname}`,
				codes[i+1].accessCode.toUpperCase().split('').join('-')
			])
			tableRows.push(codeData)
		}

		doc.autoTable({
			head: [],
			body: tableRows,
			theme: 'grid',
			styles: {font: 'Roboto-Regular'}
		})
		doc.text(`Access codes for classroom: ${this.props.classroom.name}`, 20, 20);
		doc.save(`codes.pdf`);
	}

	handleStudentDelete = () => {
		this.props.handleDeleteStudent(this.state.deleteUser.username)
		this.setState({ deleteUser: null })
	}

	render() {
		const { t } = this.props;
		return <div id="codesdisplay-component">
			<div className="header">Login Credentials</div>
			<div className='back' onClick={this.props.navigateTo}>&lt; {t('back')}</div>
			{ !this.state.deleteUser && <>
				<div className="intro container-fluid row">
					<div className="body col">
						<div className="header">{t('logincredentials')}</div>
						<div className="text">{t('wehavegenerated')}</div>
					</div>
					<div className="image col-5">
						<img src={PaperPlane} alt="Login credentials"/>
					</div>
				</div>
				<div className="codes container-fluid row">
					{
						this.props.codes.map(code => <div className="code col-6" key={code.username}>
							<div className="d-flex flex-row justify-content-between">
								<div className="name flex-grow-1">{ `${code.firstname} ${code.lastname}` }</div>
								<div className="code">{ `${code.accessCode.split('').join('-').toUpperCase()}` }</div>
								<div className="delete" onClick={() => this.setState({ deleteUser: code })}>X</div>
							</div>
						</div>)
					}
				</div>
				<div className="print row d-flex flex-row justify-content-center">
					<div className="text" onClick={this.handlePrint}><AiFillPrinter /> {t('printlogincredentials')}</div>
				</div>
				<div className="actions d-flex flex-row justify-content-end">
					<button className='primary-button' onClick={this.props.navigateTo}>{t('done')}</button>
				</div>
			</> }
			{ !!this.state.deleteUser && <div className='delete-student'>
				<img src={DeleteStudent} alt="Delete student"/>
				<div className="text">{t('areyousuretodelete')}</div>
				<div className="name">{`${this.state.deleteUser.firstname} ${this.state.deleteUser.lastname}`}</div>
				<div className="actions d-flex flex-row justify-content-center">
					<button className='danger-button' onClick={this.handleStudentDelete}>{t('done')}</button>
					<button className='secondary-button' onClick={() => this.setState({ deleteUser: null })}>{t('cancel')}</button>
				</div>
			</div>}
		</div>
	}
}

export default withTranslation()(CodesDisplay);