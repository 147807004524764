import React, { Component } from 'react';

import '../../styles/studentselection.scss'
import { HiOutlineClipboardList } from 'react-icons/hi'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CreateClass from '../../assets/img/create-class.png'
import { withTranslation } from 'react-i18next';
class StudentSelection extends Component {

	constructor(props) {
		super(props)
		this.state = {
			students: []
		}
	}

	componentDidMount() {
		if (!!this.props.students) {
			this.setState({students: this.props.students})
		}
	}

	handleTextChange = (event, index = null) => {
		let value = event.target.value
		this.addStudent( value, index );
	}

	addStudent( name, index = null ) {
		let tempStudents = this.state.students
		if (index === null) {
			tempStudents.push(name)
		} else {
			tempStudents[index] = name
		}
		this.setState({ students: tempStudents }, () => {
			if (index === null) {
				let studentTB = document.getElementsByClassName('student')
				studentTB[tempStudents.length - 1].focus()
				studentTB[tempStudents.length].value = ''
			}
		})
	}

	handlePaste = (event) => {

		event.preventDefault();
		event.stopPropagation();

		let paste = (event.clipboardData || window.clipboardData).getData('text');

		let list = paste.split('\n');

		list.forEach( (newStudent) => {
			this.addStudent( newStudent );
		});
	}

	handleStudentsSubmit = () => {
		let students = []
		this.state.students.forEach(student => {
			student = student.trim()
			if (student !== '') students.push(student)
		})
		this.props.updateData(students)
		this.props.goNext()
	}

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			let tempStudents = this.state.students
			tempStudents.push('')
			this.setState({ students: tempStudents }, () => {
				document.getElementsByClassName('student')[tempStudents.length - 1].focus()
			})
		}
	}

	deleteStudent = index => {
		let tempStudents = this.state.students
		if (index >= 0 && index < tempStudents.length) {
			tempStudents.splice(index, 1)
			this.setState({students: tempStudents})
		}
	}

	goHome = () => this.props.navigateTo()

	render() {
		const { t } = this.props;
		return <div id="studentselection-component">
			<div className="header">{t('createnewclassroom')}</div>
			<div className='back' onClick={this.goHome}>&lt; {t('back')}</div>
			<div className="intro container-fluid row">
				<div className="body col">
					<div className="header">{t('addstudents')}</div>
					<div className="text">{t('registerstudentspaste')}</div>
				</div>
				<div className="image col-5">
					<img src={CreateClass} alt="Create a new classroom"/>
				</div>
			</div>
			<div className="students container-fluid row">
				<div className="col-6">
					<div className='text-input'>
						{ this.state.students.length < 1 && <div className="background d-flex flex-column align-items-center">
							<HiOutlineClipboardList/>
							<div>{t('addorpsatemessage')}</div>
						</div>}
						{ this.state.students.map((student, index) => {
							return <div className="d-flex flex-row" key={index}>
								<input type="text" className='student flex-grow-1' value={student} onPaste={(e) => this.handlePaste(e)} onChange={(e) => this.handleTextChange(e, index)} onKeyUp={this.handleKeyPress} />
								<div className="delete" onClick={() => this.deleteStudent(index)}>X</div>
							</div>
						})}
						<input type="text" name="row" className='student' placeholder='John Doe...' onPaste={(e) => this.handlePaste(e)} onInput={(e) => this.handleTextChange(e)}/>
					</div>
				</div>
				<div className="col-1 or">{t('or')}</div>
				<div className="col-5 d-flex flex-column justify-content-between">
					<div className="csv-input d-flex flex-column align-items-center">
						<FaCloudUploadAlt className='upload-icon'/>
						<div className="text">{t('dragcsv')}</div>
						<div className="text">{t('or')}</div>
						<button className='secondary-button'>{t('selectfile')}</button>
						<div className="info align-self-start d-flex flex-row align-items-end"><AiOutlineInfoCircle/>{t('formatcsv')}</div>
					</div>
					<div className="buttons d-flex flex-row justify-content-around">
						<button className='secondary-button' onClick={this.goHome}>{t('cancel')}</button>
						<button className={ this.state.students.length > 0 ? 'primary-button' : 'disabled-button'} onClick={this.handleStudentsSubmit}>{t('next')}</button>
					</div>
				</div>
			</div>
		</div>
	}
}

export default withTranslation()(StudentSelection);