import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import * as V from 'victory';

import '../styles/skillprogress.scss'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'

class SkillProgress extends Component {

	MILISECONDS_IN_MINUTE = 1000 * 60
	MILISECONDS_IN_HOUR = this.MILISECONDS_IN_MINUTE * 60
	MILISECONDS_IN_DAY = this.MILISECONDS_IN_HOUR * 24
	MILISECONDS_IN_WEEK = this.MILISECONDS_IN_DAY * 7

	GRAPH_LINES_COLOUR = '#DDD'

	constructor() {
		super()
		const today = new Date()
		let dayOfTheWeek = today.getDay() - 1 // 0-6, Sunday is 0
		if (dayOfTheWeek < 0 ) dayOfTheWeek = 6
		const weekStart = today
			- (dayOfTheWeek * this.MILISECONDS_IN_DAY)
			- today.getHours() * this.MILISECONDS_IN_HOUR
			- today.getMinutes() * this.MILISECONDS_IN_MINUTE
			- today.getSeconds() * 1000
			- today.getMilliseconds()
		this.state = {
			weekStart
		}
	}

	splitItemsByWeeks = data => {
		const r = []
		if (!data) return r
		const { weekStart } = this.state
		for(let i = 0; i < this.props.columns; i++) {
			const timeStart = new Date(weekStart - this.MILISECONDS_IN_WEEK * (i + 1))
			const timeEnd = new Date(weekStart - this.MILISECONDS_IN_WEEK * i)
			const sub = data.filter(tag => new Date(tag.createdAt) > timeStart && new Date(tag.createdAt) < timeEnd).length
			r.unshift(sub)
		}
		return r
	}

	getSkillColour = skillid => {
		switch(skillid) {
			case 1: default: return '#6089FC'
			case 2: return '#000'
			case 3: return '#FEB23A'
			case 4: return '#27CC7D'
			case 5: return '#F36774'
		}
	}

	getWeekStart = order => {
		const { weekStart } = this.state
		const { columns } = this.props
		order = columns - order - 1
		const date = new Date(weekStart - this.MILISECONDS_IN_WEEK * order)
		return `${date.getDate()}/${date.getMonth() + 1}`
	}

	previousWeek = () => this.setState({ weekStart: this.state.weekStart - this.MILISECONDS_IN_WEEK})
	nextWeek = () => {
		const { weekStart } = this.state
		const nextWeek = weekStart + this.MILISECONDS_IN_WEEK
		const now = (new Date()).getTime()
		//if (nextWeek < now) {
			this.setState({ weekStart: nextWeek })	
		//} 
	}

	render() {
		const { skills, columns } = this.props
		const skillsData = skills.map(skill => this.splitItemsByWeeks(skill.tags))
		const supportLines = 4
		const victoryTheme = {
			axis: {
				style: {
					grid: {
						fill: "none",
						stroke: this.GRAPH_LINES_COLOUR,
						strokeWidth: 4,
						strokeDasharray: '40, 30',
					},
					tickLabels: {
					  fill: '#888',
					  padding: 5
					},
				}
			}
		}
		return <div id="skillprogress-component" className='box-shadow'>
			<div className="header d-flex flex-row justify-content-between align-items-center">
				<div className="week-nav" onClick={this.previousWeek}><IoIosArrowBack /></div>
				<div className="title">Skills Curve</div>
				<div className="week-nav" onClick={this.nextWeek}><IoIosArrowForward /></div>
			</div>
			<div className="chart">
				<V.VictoryChart theme={victoryTheme} maxDomain={{ y: Math.max(...skillsData.flat()) * 1.3 }} height={300} >
					{ [...Array(supportLines).keys()].map((line, index) => <V.VictoryAxis key={index} tickFormat={() => ''} offsetY={200 / supportLines * (line + 1) + 50} style={{ axis: {
						fill: "none",
						stroke: this.GRAPH_LINES_COLOUR,
						strokeWidth: 4,
					}}} />)}
					<V.VictoryAxis tickValues={[...Array(columns).keys()]} tickFormat={t => this.getWeekStart(t)} theme={victoryTheme} />
					{ skillsData.map((skill, index) => <V.VictoryLine
												key={index}
												data={skill.map((value, index) => { return { x: index, y: value} })}
												interpolation="monotoneX"
												style={{
													data: {
														stroke: this.getSkillColour(skills[index]._id),
														strokeWidth: 4
													}
												}}
												/>)}
				</V.VictoryChart>
			</div>
			<div className="legend d-flex flex-row justify-content-between flex-wrap">
				{ this.props.skills.map(skill => <div key={skill._id} className='skill d-flex flex-row align-content-center'>
					<div className='box' style={{ backgroundColor: this.getSkillColour(skill._id) }}></div>
					<div className="name">{skill.title}</div>
				</div>)}
			</div>
		</div>
	}
}

export default withTranslation()(SkillProgress)
